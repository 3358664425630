import React, { useEffect } from 'react';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import Head from 'next/head';
import dynamic from 'next/dynamic';

// Utils
import { useAppContext } from '../src/store/AppContext';
import SCOPES from '../src/gate/scopes';
import withSidebar from '../src/hocs/withSidebar';
import withAuth from '../src/hocs/withAuth';
import withKeyAccountContext from '../src/hocs/withKeyAccountContext';
import PermissionsGate, { isGranted } from '../src/gate/PermissionsGate';
import displayName from '../src/utils/displayName';

// Hooks
import useMenuEntriesByRole from '../src/hooks/useMenuEntriesByRole';
import useRouter from '../src/hooks/useRouter';
import useTranslation from '../i18n/client';

// Components
const OfficeLayout = dynamic(() => import('../components/layout/OfficeLayout/OfficeLayout'));
const StatisticsTable = dynamic(() => import('../components/admin/statistics/StatisticsTable/StatisticsTable'));
const Catalog = dynamic(() => import('../components/template/Catalog/Catalog'));
const KeyAccountClientStatisticsTable = dynamic(
  () => import('../components/organisms/KeyAccountAdminStatisticsTable/KeyAccountAdminStatisticsTable'),
);
const MultiCenterDirectorStatisticsTable = dynamic(
  () => import('../components/organisms/MultiCenterDirectorStatisticsTable/MultiCenterDirectorStatisticsTable'),
);

import styles from '../styles/pages/index.module.scss';

function Home() {
  const { t } = useTranslation();
  const router = useRouter();
  const [{ user }] = useAppContext();

  const menuEntries = useMenuEntriesByRole(user.roles);
  useEffect(() => {
    if (
      !isGranted(user, [SCOPES.canViewClientHome]) &&
      !isGranted(user, [SCOPES.canViewClientDashboard]) &&
      !isGranted(user, [SCOPES.canViewAdminDashboard]) &&
      !isGranted(user, [SCOPES.canViewKeyAccountAdminDashboard])
    ) {
      if (menuEntries?.length && menuEntries[0]?.path) router.push(menuEntries[0].path);
    }
  }, [menuEntries]);

  return user ? (
    <>
      {!isGranted(user, [SCOPES.canViewClientDashboard]) ? (
        <PermissionsGate scopes={[SCOPES.canViewClientHome]}>
          <div className={styles.container}>
            <Catalog />
          </div>
        </PermissionsGate>
      ) : null}

      <PermissionsGate scopes={[SCOPES.canViewClientDashboard]}>
        <Head>
          <title>{t('pageTitle.clientDashboard')}</title>
        </Head>
        <OfficeLayout title={`${t('hello')} ${displayName(`${user.firstname}`)}`}>
          <MultiCenterDirectorStatisticsTable />
        </OfficeLayout>
      </PermissionsGate>

      <PermissionsGate scopes={[SCOPES.canViewAdminDashboard]}>
        <Head>
          <title>{t('pageTitle.adminDashboard')}</title>
        </Head>
        <OfficeLayout title={`${t('hello')} ${displayName(`${user.firstname}`)}`}>
          <StatisticsTable />
        </OfficeLayout>
      </PermissionsGate>

      <PermissionsGate scopes={[SCOPES.canViewKeyAccountAdminDashboard]}>
        <Head>
          <title>{t('pageTitle.adminDashboard')}</title>
        </Head>
        <OfficeLayout title={`${t('hello')} ${displayName(`${user.firstname}`)}`}>
          <KeyAccountClientStatisticsTable />
        </OfficeLayout>
      </PermissionsGate>
    </>
  ) : null;
}

export async function getServerSideProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale || 'fr', ['common', 'errors'])),
    },
  };
}

export default withAuth(
  withKeyAccountContext(withSidebar(Home, { overlay: false, cart: { showCart: false }, activeHomeMenuEntry: true })),
);
